<template>
    <div class="moreVideoItem">
        <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh"
            :refreshing="refreshing" :isNoData="isNoData" :firstLoading="firstLoading">
            <div class="video-list">
                <div v-for="item in videoList" :key="item.id">
                    <div v-if="advList.length > 0 && item.adv" class="l_adv">
                        <AdvSwiper :advList="item.adv" />
                    </div>
                    <videoListMore v-else :item="item" />
                </div>
            </div>
        </Pullrefresh>
        <nodata v-show="noData" />
    </div>
</template>

<script>
    import videoListMore from "../widget/videoListMore.vue";
    import Pullrefresh from "@/views/widget/PullRefresh.vue";
    import AdvSwiper from "@/components/AdvSwiper/index.vue";
    import { tagList } from "@/api/app.js";
    import { getAdItem, AdType } from "@/utils/getAdv.js";
    export default {
        props: {
            searchType: {
                type: String,
                default: "",
            },
            filterConditions: {
                type: String,
            }
        },
        components: {
            videoListMore,
            Pullrefresh,
            AdvSwiper,
        },
        data() {
            return {
                videoList: [], //视频列表
                oldVideoList: [],
                pageNum: 1, //页码
                noData: false, //暂无数据
                loading: false, //下滑loading
                finished: false, //数据是否加载完毕
                refreshing: false, //下拉下载loading
                isNoData: false, //数据是否为空
                firstLoading: true, //是否第一次加载
                advList: [],
            };
        },
        created() {
            // console.log(this.filterConditions)
            this.advList = getAdItem(AdType.content2x2);
            this.getList();
        },
        methods: {
            async getList() {
                //通过接口得到视频列表
                let req = {
                    id: this.$route.query.id,
                    isVip: true,
                    pageNum: this.pageNum,
                    pageSize: 15,
                    filterConditions: this.filterConditions
                };
                this.loading = true;
                let ret = await this.$Api(tagList, req);
                this.loading = false;
                this.refreshing = false;
                this.firstLoading = false;
                // if(!this.$refs.pull) return;
                // this.$refs.pull.loading = false;
                if (ret.code == 200) {
                    let list = ret.data.mediaInfos ? ret.data.mediaInfos : [];
                    // list.splice(0,5)
                    if (this.advList.length > 0) {
                        this.oldVideoList = this.oldVideoList.concat(list);
                        let advNum = Math.floor(this.oldVideoList.length / 4);
                        this.videoList = JSON.parse(JSON.stringify(this.oldVideoList));
                        let advLength = this.advList.length;
                        for (let i = 0; i < advNum; i++) {
                            let advItem = this.advList[i % advLength];
                            this.videoList.splice(5 * i + 4, 0, {
                                adv: [advItem]
                            });
                        }
                    } else {
                        this.videoList = this.videoList.concat(list);
                    }
                    if (list.length == 0) {
                        // this.$refs.pull.finished = true;
                        this.finished = true;
                    } else {
                        // this.$refs.pull.finished = false;
                        this.finished = false;
                    }
                    if (this.pageNum == 1 && list.length == 0) {
                        this.noData = true;
                    }
                }
            },
            onLoad() {
                this.pageNum++;
                this.getList();
            },
            onRefresh() {
                this.refreshing = true;
                this.finished = false;
                this.pageNum = 1;
                this.videoList = [];
                this.getList();
            },
        }
    }
</script>

<style lang="scss" scoped>
.moreVideoItem {
  height: calc(100vh - 96px);
//   height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 10px 5px 30px;
  box-sizing: border-box;
}
.video-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.l_adv {
  width: 169.98px;
  height: 96px;
//   padding: 6px 0 6px 6px;
//   padding: 6px 0 0 6px;
//   box-sizing: border-box;
margin: 6px 0 0 6px;
  overflow: hidden;
  .adv {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
  }
  /deep/ .swiper-container,
  /deep/ .swiperSlide {
    width: 100%;
    height: 100%;
  }
}
</style>