<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box flex-between">
        <div class="left" @click="$router.go(-1)"></div>
        <div class="center ellipsis">{{ $route.query.name }}</div>
        <div class="right"></div>
      </div>
      <van-tabs v-model="active" animated @change="changeTab">
        <van-tab title="热门推荐" key="hot" >
          <moreVideoItem />
        </van-tab>
        <van-tab title="最多观看" key="currWatchTimes">
          <moreVideoItem filterConditions="currWatchTimes" />
        </van-tab>
        <van-tab title="最新上架" key="onMarketAt">
          <moreVideoItem filterConditions="onMarketAt"  />
        </van-tab>
      </van-tabs>
      
    </div>
    <div class="backTop" v-if="scrollTop > 300" @click="backTop"></div>
  </div>
</template>
<script>
import moreVideoItem from "./moreVideoItem.vue";
export default {
  name: "moreVideo",
  components: {
    moreVideoItem
  },
  data() {
    return {
      active: 0,
      btnFlag: false,
      scrollTop: 0,
    };
  },
  watch: {
    $route(to) {
      window.removeEventListener('scroll', this.scrollToTop, true)
      if (to.path == "/moreVideo") {
        window.addEventListener('scroll', this.scrollToTop, true)
      }
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop, true)
  },
  created() {
    // console.log("---------------")
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop, true)
    let dom = document.querySelectorAll(".moreVideoItem");
    this.scrollTop = dom[this.active] ? dom[this.active].scrollTop : 0;
  },
  methods: {
    changeTab() {
      let dom = document.querySelectorAll(".moreVideoItem");
      this.scrollTop = dom[this.active] ? dom[this.active].scrollTop : 0;

    },
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop () {
        let dom = document.querySelectorAll(".moreVideoItem");
        let timer = setInterval(() => {
          let ispeed = Math.floor(-this.scrollTop / 5)
          dom[this.active].scrollTop = dom[this.active].scrollTop = this.scrollTop + ispeed
          if (this.scrollTop === 0) {
            clearInterval(timer)
          }
        }, 16)
    },
    scrollToTop () {
      let dom = document.querySelectorAll(".moreVideoItem");
      this.scrollTop = dom[this.active].scrollTop
    }
  },
};
</script>
<style lang="scss" scoped>
.search {
  // /deep/ .van-tabs {
  //   height: calc(100% - 52px);
  // }
  // /deep/ .van-tabs__content {
  //   height: 100%;
  //   // height: calc(100% - 52px);
  // }
  // /deep/ .van-tab__pane {
  //   height: 100%;
  //   // height: calc(100% - 52px);
  // }
}
.search-all {
  height: 100%;
  // overflow-y: auto;
}
.search-box {
  padding: 0 16px;
  height: 52px;
  align-items: center;
  background: $vermillion;
}
.left {
  width: 22px;
  height: 22px;
  background: url("../../assets/png/back_icon.png") center center no-repeat;
  background-size: 100%;
}
.center {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}
.right {
  width: 22px;
  height: 30px;
}
.section {
  // height: calc(100% - 52px);
  // overflow-y: auto;
  padding: 10px 5px 30px;
  box-sizing: border-box;
}
.video-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.l_adv {
  width: 178px;
  height: 118px;
  padding: 6px 0 6px 6px;
  box-sizing: border-box;
  overflow: hidden;
  .adv {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
  }
  /deep/ .swiper-container,
  /deep/ .swiperSlide {
    width: 100%;
    height: 100%;
  }
}
.backTop {
  position: fixed;
  right: 8%;
  bottom: 10%;
  width: 50px;
  height: 50px;
  background: url("../../assets/png/backTop.png");
  background-size: 100% 100%;
  z-index: 3;
}
</style>
